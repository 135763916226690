<!-- 充值 -->
<template>
  <div class="box100 reCont">
    <div class="reRow flexAC">
      <p style="margin-right: 16px;">客户查询</p>
      <el-popover placement="bottom-start" v-model="visible" trigger="manual">
        <div class="userRow" v-for="(item, index) in userList" :key="index" @click="selectUser(item)">{{ item.username }} / {{ item.uphone }}</div>
        <div slot="reference">
          <el-input placeholder="请输入客户手机号/名称" v-model="userKey" @input="handGetList"></el-input>
        </div>
      </el-popover>
    </div>
    <div class="userBox">
      <div class="userInfo flexF" v-if="showUserInfo">
        <p class="userItem"><span style="width: 60px;">姓名</span><span class="utValue">{{ userInfo.username }}</span></p>
        <p class="userItem"><span style="width: 60px;">绑定手机</span><span class="utValue">{{ userInfo.uphone }}</span></p>
        <p class="userItem"><span style="width: 60px;">账户余额</span><span class="utValue">{{ userInfo.balance }}</span></p>
        <p class="userItem"><span style="width: 60px;">账号积分</span><span class="utValue">{{ userInfo.points }}</span></p>
      </div>
      <div class="userInfo" v-if="!showUserInfo" style="line-height: 96px;text-align: center">暂无客户信息</div>
    </div>
    <div class="cardBox">
      <!-- 充值列表 -->
      <div class="flexF">
        <div class="cardItem" v-for="(item, index) in rechargeList" :key="index"
             :class="{'cardItemAc':(cardId === item.mtid)}" @click="selectCard(item)">
          <p class="cardNum">充值{{ item.mtcmoney }}元</p>
          <p class="cardGive">赠送{{ item.mtzmoney }}元</p>
        </div>
      </div>
      <div class="notList" v-if="rechargeList.length === 0" style="margin-top: 20%;">
        <img src="../../assets/img/noData.png"/>
        <p>暂无数据</p>
      </div>
      <!-- 抵扣券 -->
      <div class="bondBox" v-if="bondList.length !== 0">
        <p class="bondTip">充{{ cardMoney }}元赠</p>
        <div class="flexF">
          <div class="bondItem flexCB" v-for="item in bondList" :key="item.mdid">
            <div class="bondLeft">
              <p style="margin: 25px 0 5px;">¥ {{ item.mdprice }}</p>
              <p style="font-size: 12px;">赠送价值</p>
            </div>
            <div class="bondRig">
              <p>{{ item.mdname }}</p>
              <p class="bondDesc">{{ item.mdmark }}</p>
              <p class="bondDesc">有效期 {{ item.mdeffe_str }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flexCE" style="position: absolute;bottom: 0px;right: 50px;">
      <div class="settingCancel" @click="FromCancel">取消</div>
      <div class="settingConfirm" @click="rechargeSubmit">确定</div>
    </div>
  </div>
</template>

<script>
import { customerQueryKey } from '@/api/customer'
import { marketGetAll } from '@/api/market'
import { rechargeAdd, rechargeBondQuery } from '@/api/reception'

export default {
  name: 'recharge',
  data () {
    return {
      userKey: '',
      showUserInfo: false,
      userList: [],
      visible: false,
      userInfo: null,
      rechargeList: [],
      cardId: '',
      cardMoney: 0,
      bondList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      marketGetAll().then(res => {
        if (res.status === 1) {
          this.rechargeList = res.data.data
        }
      })
    },
    handGetList (val) {
      if (val !== '') {
        customerQueryKey({ username: val }).then(res => {
          if (res.status === 1) {
            if (res.data.data.length > 0) {
              this.userList = res.data.data
              this.visible = true
            } else {
              this.userList = []
              this.visible = false
            }
          }
        })
      } else {
        this.userList = []
        this.visible = false
      }
    },
    selectUser (item) {
      this.visible = false
      this.showUserInfo = true
      this.userKey = ''
      this.userInfo = { ...item }
    },
    selectCard (item) {
      this.cardId = item.mtid
      this.cardMoney = item.mtcmoney
      rechargeBondQuery({ mtid: item.mtid }).then(res => {
        if (res.status === 1) {
          this.bondList = res.data.data
        }
      })
    },
    // 取消
    FromCancel () {
      this.$emit('addCancel')
    },
    // 充值提交
    rechargeSubmit () {
      if (this.userInfo === null) {
        this.$message.warning('请查询客户信息')
      } else if (this.cardId === '') {
        this.$message.warning('请选择充值套餐')
      } else {
        rechargeAdd({ userid: this.userInfo.userid, mtid: this.cardId }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.$emit('addSuccess')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.reCont{
  font-size: 14px;
}

.reRow{
  padding: 16px 50px 16px;
}
.userInfo{
  height: 96px;
}
.cardBox{
  width: 100%;
  padding: 24px 50px 0px;
  box-sizing: border-box;
}
.cardItem{
  width: 22%;
  margin-right: 3%;
  text-align: center;
  border: 1px solid #707070;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px;
  cursor: pointer;
}
.cardNum{
  font-size: 20px;
  margin-bottom: 4px;
}
.cardItem .cardGive{
  color: #576271;
}
.userRow{
  margin: 8px 0px;
  cursor: pointer;
}
.cardItemAc{
  background: #14151D;
  color: #FCDEA0;
}
.cardItemAc .cardGive{
  color: #FCDEA0;
}
/* 抵扣券 */
.bondTip{
  width: 100%;
  color: #333;
  font-size: 18px;
}
.bondTip span{
  color: #999;
  font-size: 16px;
}
.bondItem{
  width: 48%;
  margin-right: 3%;
  height: 96px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  margin-top: 20px;
  position: relative;
}
.bondItem:nth-child(even) {
  margin-right: 0;
}
.bondLeft{
  width: 30%;
  height: 100%;
  background: linear-gradient(134deg, #2075F5 0%, #63A1FE 100%);
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.bondRig{
  width: 70%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
}
.bondDesc{
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}
</style>
