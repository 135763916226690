<template>
  <div class="box100">
    <div class="whiteBg" v-if="!showDetail">
      <!-- <div class="settingAdd" @click="handRecharge"><span>立即充值</span></div> -->
      <div class="settingBlue" @click="handResh"><span>刷新</span></div>
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="searchKey"  placeholder="请输入账号/手机号" class="inputSearch"></el-input>
        <el-date-picker v-model="searchDate" type="daterange" value-format="yyyy-MM-dd" class="searchDate"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="cashList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="姓名"
            prop="username">
          </el-table-column>
          <el-table-column
            label="绑定手机"
            prop="uphone">
          </el-table-column>
          <el-table-column
            label="账户余额"
            prop="balance">
          </el-table-column>
          <el-table-column
            label="账号积分"
            prop="points">
          </el-table-column>
          <el-table-column
            label="充值"
            prop="itpmark">
          </el-table-column>
          <el-table-column
            label="美容师"
            prop="sbecode">
          </el-table-column>
          <el-table-column
            label="时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope" v-if="roleBtn[1].power">
              <el-button type="text" size="small" v-if="scope.row.sbeid === ''" @click="handDistribute(scope.row)">分配美容师</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <div class="detailForm" v-if="showDetail" style="padding: 0px 0px 24px;">
      <Recharge @addCancel="addCancel" @addSuccess="addSuccess"></Recharge>
    </div>

    <el-dialog title="分配美容师" :visible.sync="beauticianShow" width="1200px" center class="gyDialog" :close-on-click-modal="false" :before-close="dialogClose">
      <Beaut @closeCancel="dialogClose" @selectConfirm="selectConfirm"></Beaut>
    </el-dialog>
  </div>
</template>

<script>
import Recharge from '@/components/recepion/recharge'
import Beaut from '@/components/recepion/beaut'
import { rechargeQuery, rechargeCosmet } from '@/api/reception'
export default {
  components: {
    Recharge,
    Beaut
  },
  props: ['roleBtn'],
  data () {
    return {
      showDetail: false,
      searchKey: '',
      searchDate: '',
      page: 1,
      size: 10,
      total: 0,
      cashList: [],
      beauticianShow: false,
      selectTtpid: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let st = ''
      let et = ''
      if (this.searchDate !== '') {
        st = this.searchDate[0]
        et = this.searchDate[1]
      }
      const params = {
        username: this.searchKey,
        itpstime: st,
        itpetime: et,
        page_index: this.page,
        page_size: this.size
      }
      rechargeQuery(params).then(res => {
        if (res.status === 1) {
          this.cashList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    // 刷新
    handResh () {
      this.init()
    },
    handleSearch () { // 搜索
      this.init()
    },
    handleReset () { // 重置
      this.searchKey = ''
      this.searchDate = ''
      this.init()
    },
    pageChange (page) { // 翻页
      this.page = page
      this.init()
    },
    // 点击充值
    handRecharge () {
      this.showDetail = true
    },
    // 关闭充值
    addCancel () {
      this.showDetail = false
    },
    // 充值成功
    addSuccess () {
      this.showDetail = false
      this.init()
    },
    // 分配美容师[关闭]
    dialogClose () {
      this.beauticianShow = false
    },
    // 分配美容师[展示]
    handDistribute (row) {
      this.beauticianShow = true
      this.selectTtpid = row.itpid
    },
    // 分配美容师[确实]
    selectConfirm (res) {
      this.beauticianShow = false
      rechargeCosmet({ itpid: this.selectTtpid, sbeid: res.sbeid }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.reception_top{
  width: 100%;
  height: 160px;
}
.reception_fot{
  margin-top: 8px;
  width: 100%;
  height: calc(100% - 160px);
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
}
</style>
